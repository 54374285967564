/* eslint-disable prettier/prettier */
import $merge from 'lodash.merge'

const baseConfig = require('../../config.base')

const DEFAULT = {
  middlewares: [],
  iconVisibleDesktop: false,
  hideTitle: false,
  visibleInMenu: true,
}

class VoicerConfigPageEntry {
  constructor(entry) {
    this.entry = $merge({
      ...DEFAULT
    }, entry)
  }

  get hideTitle() {
    return this.entry.hideTitle
  }

  get icon() {
    return this.entry.icon
  }

  get iconAttrs() {
    return this.entry.iconAttrs
  }

  get iconVisibleDesktop() {
    return this.entry.iconVisibleDesktop
  }

  get visibleInMenu() {
    return this.entry.visibleInMenu
  }

  get payload() {
    return this.entry.options.payload
  }

  get reducer() {
    return this.entry.options.reducer
  }

  get router() {
    return this.entry.router
  }

  get slug() {
    const slug = this.entry.slug

    if (slug) {
      return slug
    }

    return this.entry.router.path.replace('/', '')
  }

  get options() {
    return this.entry.options
  }

  title(locale) {
    if (typeof this.entry.title === 'string') {
      return this.entry.title
    } else if (this.entry.title && typeof this.entry.title === 'object') {
      return this.entry.title[locale]
    } else {
      return ''
    }
  }

  setInit(init) {
    $merge(this.entry.options.init, init)

    return this
  }

  setOptions(options) {
    $merge(this.entry.options, options)

    return this
  }

  setMiddleware(middleware) {
    this.entry.middlewares.push(middleware)

    return this
  }

  setReducer(reducer) {
    this.entry.options.reducer = reducer

    return this
  }

  setRouter(router) {
    $merge(this.entry.router, router)

    return this
  }

  toObject() {
    return this.entry
  }
}

export default class VoicerConfigPages {
  constructor() {
    this.pages = baseConfig.basePages.map((page) => new VoicerConfigPageEntry(page))
  }

  add(entry) {
    if (typeof entry !== 'object') {
      throw new TypeError(`add accepts only object, you've passed type ${typeof entry}`)
    }

    this.pages.push(new VoicerConfigPageEntry(entry))

    return this
  }

  update(slug, options) {
    this.pages = this.pages.map((page) => {
      if (page.slug === slug) {
        if (typeof options === 'function') {
          return options(page)
        } else {
          return new VoicerConfigPageEntry($merge(page.entry, options))
        }
      }
      return page
    })

    return this
  }

  remove(slug) {
    this.pages = this.pages.filter((page) => page.slug !== slug)

    return this
  }

  toArray() {
    return this.pages
  }
}
