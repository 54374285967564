import $clonedeep from 'lodash.clonedeep'

import AssetImage1 from '~/assets/images/exc-bg-q-1.png'
import AssetImage2 from '~/assets/images/exc-bg-q-2.png'
import AssetImage1m from '~/assets/images/exc-bg-q-1m.png'
import AssetImage2m from '~/assets/images/exc-bg-q-2m.png'
import AssetImageGob from '~/assets/images/exc-gob.png'
import AssetImageGobFrites from '~/assets/images/exc-gob-frites.png'

// trick pour quick / bk
// un sujet de contenus rotatifs est à l'étude sur Brocoli
// rendant obsolète ce repo dans un avenir (incertain)
// oui, c'est faillible ici (on considère que l'heure du nav est UTC+2 (fr))
const CONTENTS_TABLE = [
  {
    slug: 'matinale',
    background: AssetImage1,
    backgroundMobile: AssetImage1m,
    nextSlug: 'la_radio_quick',
    activeFn: (currentHour, currentMinutes) => {
      return currentHour >= 8 && currentHour < 11
    },
    gob: AssetImageGob,
  },
  {
    slug: 'la_radio_quick',
    background: AssetImage1,
    backgroundMobile: AssetImage1m,
    nextSlug: 'la_radio_quick-hpi4p',
    activeFn: (currentHour, currentMinutes) => {
      if (currentHour >= 11 && currentHour < 22) return true
      else if (currentHour === 23 && currentMinutes < 30) return true
      return false
    },
    gob: AssetImageGobFrites,
  },
  {
    slug: 'la_radio_quick-hpi4p',
    nextSlug: 'matinale',
    background: AssetImage2,
    backgroundMobile: AssetImage2m,
    activeFn: (currentHour, currentMinutes) => {
      if (currentHour >= 0 && currentHour < 8) return true
      else if (currentHour === 22 && currentMinutes >= 30) return true
      return false
    },
    gob: false,
  },
]

export const CONTENTS_DIRECTIVES = [...CONTENTS_TABLE]

export function getCurrentContent(allContents) {
  const findContentFromTable = (element) => {
    const tryStrategy = allContents.find((content) => {
      return content.slug === element.slug
    })

    if (!tryStrategy) {
      return $clonedeep(allContents[0] || {})
    }

    return $clonedeep(tryStrategy)
  }

  const contentActivatorCall = () => {
    const currentTime = new Date()
    const currentHour = currentTime.getHours()
    const currentMinutes = currentTime.getMinutes()

    let content = null

    for (let i = 0; i < CONTENTS_TABLE.length; i++) {
      const table = CONTENTS_TABLE[i]

      if (table.activeFn(currentHour, currentMinutes)) {
        content = table
        break
      }
    }

    if (content) {
      return findContentFromTable(content)
    }
  }

  return contentActivatorCall()
}
